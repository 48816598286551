const jhonData = {
    "name": "Kunal Kamble.",
    "name2": "Kunal Kamble.",
    "name3": "Kunal Kamble",
    "role": "Independent Consulatant,",
    "ptext": "Full-Stack Javascript Developer.",
    "socialLinks":[
        {
          "name": "Linkedin",
          "url": "http://www.linkedin.com/in/kunalkamble",
          "className": "social_linkedin"
        },
        {
          "name":"twitter",
          "url":"http://www.twitter.com/kunalkamble",
          "className":"social_twitter"
        },
        {
            "name":"instagram",
            "url":"http://www.instagram.com/kunalkamble",
            "className":"social_instagram"
          }
      ],
      "aboutme": "About Me",
      "aboutdetails": `I am Kunal Kamble Building platform using Javascript frameworks, such as Angularjs, Reactjs, Vuejs, Nodejs etc (Frontend and Backend).

      Building custom reusable components, services and npm packages.
      
      Using Git and CI tools to manage and coordinate code development across multiple teams of Developers.`,
      "service":[
          {
            "id" : 1,
            "iconName": "icon-desktop",
            "serviceTitle": "Web Design",
            "sDetails" : ""
          },
          {
            "id" : 2,
            "iconName": "icon-pencil",
            "serviceTitle": "Development",
            "sDetails" : ""
          },
          {
            "id" : 3,
            "iconName": "icon-presentation",
            "serviceTitle": "Marketing",
            "sDetails" : ""
          },
          {
            "id" : 4,
            "iconName": "icon-linegraph",
            "serviceTitle": "Strategy",
            "sDetails" : ""
          },
          {
            "id" : 5,
            "iconName": "icon-gears",
            "serviceTitle": "Support",
            "sDetails" : ""
          },
          {
            "id" : 6,
            "iconName": "icon-tools",
            "serviceTitle": "Mentoring",
            "sDetails" : ""
          }
      ],
      "contact":[
        {
          "id": "1",
          "title": "Contact Info",
          menuItems: [
            {
              id: 1,
              icon: 'icon_pin_alt',
              text: 'Address: Toronto',
            },
            {
              id: 2,
              icon: 'icon_globe',
              text: 'kunalkamble.com',
            },
            {
              id: 2,
              icon: 'icon_mail_alt',
              text: 'kunal @ kunalkamble.com',
            }
          ],
        socialItems: [
          {
            id: 1,
            icon: 'social_linkedin',
            text: '@kunalkamble',
          },
          {
            id: 2,
            icon: 'social_instagram',
            text: '@kunalkamble',
          },
          {
            id: 3,
            icon: 'social_twitter',
            text: '@kunalkamble',
          }
        ]
        },
      ],
    contacttitle: 'Contact Form',
}

export default jhonData;

